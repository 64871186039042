.okiro__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  padding: 2rem 4rem;
  position: relative;
  user-select: none;
}

.okiro__header__logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.okiro__header__logo img {
  width: 120px;
  height: 45px;
}

.okiro__header__links,
.okiro__header__sign {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.okiro__header__sign {
  justify-content: flex-end;
}

.okiro__header__links ul,
.okiro__header__sign ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.okiro__header__links li,
.okiro__header__sign li,
.okiro__header__hamburger--linksDiv li {
  display: flex;
  list-style: none;
  margin: 0rem 0.7rem;

  font-family: var(--font-base);
  font-size: 19px;
  line-height: 1.5;
  color: var(--color-font);
  font-weight: 900;
  cursor: pointer;
  position: relative;
}

.okiro__header__links {
  position: relative;
}

.okiro__header__dotLinks {
  display: flex;
  justify-content: flex-start;
  min-width: 170px;
  position: absolute;

  margin: 2rem 0rem 0rem -3rem;

  background: var(--color-two);
  padding: 1rem 0.7rem;
  border-radius: 10px;

  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.okiro__header__dotLinks ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.okiro__header__dotLinks li {
  text-align: left;
  margin: 0.3rem 0.7rem;
}

.special-link:hover:before,
.special-link:hover:before {
  position: absolute;
  width: 7px;
  height: 7px;
  content: "";
  border-radius: 100%;
  background: var(--color-three);
  top: 1px;
  right: -7px;

  transition: background-color 0.3s ease;
}

.special-link:active:before,
.special-link:focus:before {
  background: var(--accent-color);
}

.isActive::before {
  background: var(--accent-color);
  position: absolute;
  width: 7px;
  height: 7px;
  content: "";
  border-radius: 100%;
  top: 1px;
  right: -7px;
}

.okiro__header__hamburger {
  display: none;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  position: relative;
}

.okiro__header__hamburger--linksDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 1.5rem;

  background-color: var(--color-two);
  top: 170%;
  right: -1%;
  border-radius: 10px;
  min-width: 170px;
  z-index: 5;

  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.okiro__header__hamburger--linksDiv ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0rem 1rem;
}

.okiro__header__hamburger--linksDiv li {
  margin: 0.3rem;
  font-size: 16px;
  user-select: none;
}

.okiro__header__hamburger--linkDiv-search {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--accent-color);
  padding: 1rem;
  margin-top: 0.7rem;

  font-family: var(--font-base);
  letter-spacing: 0.5px;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
  cursor: pointer;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.okiro__header__hamburger--linkDiv-search svg {
  margin-left: 10px;
}

@media screen and (max-width: 1010px) {
  .okiro__header__sign,
  .okiro__header__links {
    display: none;
  }

  .okiro__header__logo {
    justify-content: flex-start;
  }

  .okiro__header__hamburger {
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .okiro__header {
    padding: 1rem;
  }

  .okiro__header__logo img {
    width: 90px;
    height: 35px;
  }
}
